.asap-new-remains-form-container {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
  padding: 12px 22px;
  border-radius: 8px;
  background-color: #fff; }

.asap-icon-contaner {
  background-color: #ff3337;
  display: flex; }

.asap-cancel-icon-contaner {
  background-color: #d7d7d8;
  color: #292929;
  border-radius: 24px;
  width: 24px;
  height: 24px; }
  .asap-cancel-icon-contaner:hover {
    cursor: pointer; }

.asap-confirm-icon-contaner {
  background-color: #009C68;
  color: #fff;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  padding: 2px; }
  .asap-confirm-icon-contaner:hover {
    cursor: pointer; }

.asap-new-remains-action-block {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px; }
  .asap-new-remains-action-block:hover {
    cursor: pointer; }

.asap-auto-context-container {
  position: absolute;
  width: 98%;
  text-align: left;
  margin-top: 1px;
  z-index: 989;
  cursor: default;
  user-select: none; }

.asap-auto-context {
  background: #fff;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 0px;
  border: 1 solid #ced4da;
  border-radius: 0 0 24px 24px;
  padding-bottom: 4px;
  overflow: hidden;
  height: 300px;
  margin-top: 8px; }

.example-enter {
  opacity: 0.01; }

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.example-leave {
  opacity: 1; }

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in; }

.asap-foodstation-table-remains {
  max-height: 440; }

.asap-foodstation-table-new-remains-row {
  position: absolute;
  margin-top: 4px;
  z-index: 900;
  border-bottom: 0px solid #fff; }

.asap-foodstation-table-row:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
  cursor: pointer; }

.asap-remains-table-container {
  min-height: 520px;
  padding-right: 40px; }
  .asap-remains-table-container .table td,
  .asap-remains-table-container .table th {
    border-top: 0px; }

.asap-foodstation-latlong-label {
  color: #aaa5a5; }

.asap-tab-navigation-container {
  margin-left: 2px;
  margin-top: 12px; }

.asap-tab-contaner {
  background-color: #311d5c; }

.asap-table-header-remains .table td,
.asap-table-header-remains .table th {
  border-top: 0px; }

.asap-table-header-remains table th {
  border-top: 0px; }

.asap-table-header-remains table tr {
  border-top: 0px; }

.asap-bar-code-row {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis; }

.asap-number-row {
  text-align: center; }

.asap-foodstation-table-row:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
  cursor: pointer; }

.asap-foodstation-latlong-label {
  color: #aaa5a5; }

.asap-table-foodstation-remains {
  height: 300px; }
